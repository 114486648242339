import { CurrencyPipe } from "@angular/common";

export function toPanSecretResume(text: string){
    const asterisk = '******';
  return text.replace(/^[A-Z]{2}[0-9]{2,18}/, asterisk);
  }

export function toHideAmount(number: number){
    const numToString = number.toString();
    const asterisk = '*';
    return numToString.replace(/[0-9.]/g, asterisk) + '€';
  }

  export function toHideAmountAsterisk(){
    const hiddenAmount = '*****';
    return hiddenAmount + '€';
}

  export function setCurrencyFormat(initialNumber: string) {
    const currencyPipe = new CurrencyPipe('es-ES')
    const operationAmountInput = initialNumber.replace(/,/g, '.');
    const formattedAmount = currencyPipe.transform(operationAmountInput, ' ', 'symbol', '1.2-2' , 'es')
    return formattedAmount
  }

  export function containsNumbers(str: string) {
    return /[0-9]/.test(str);
  }
  export function preventLetters(event: KeyboardEvent) {
    const key = event.key;
    if ((key >= 'a' && key <= 'z') || key === '.') {
      event.preventDefault();
    }
  }

  export function preventLettersSimbols(event: KeyboardEvent) {
    const key = event.key;
    const inputElement = event.target as HTMLInputElement;
    if (!/^[0-9]$/.test(key) && !(inputElement.value.length === 0)) {
      event.preventDefault();
    }
  }
  export function preventLettersWithPrefix(event: KeyboardEvent) {
    const key = event.key;
    const inputElement = event.target as HTMLInputElement;
    if (!/^[0-9]$/.test(key) && !(key === "+" && inputElement.value.length === 0)) {
      event.preventDefault();
    }
  }

  export function twoDigitsAfterComma(event: KeyboardEvent) {
    const key = event.key;
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;

    // Permitir control keys como backspace, delete, etc.
    const controlKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];
    if (controlKeys.includes(key)) {
        return; // No hagas nada si se presiona una tecla de control
    }

    // Comprueba si la tecla presionada es un número
    if (key >= '0' && key <= '9') {
        const commaIndex = currentValue.indexOf(',');
        if (commaIndex !== -1) {
            // Calcula cuántos dígitos hay después de la coma
            const digitsAfterComma = currentValue.slice(commaIndex + 1);
            if (digitsAfterComma.length >= 2) {
                // Previene la entrada si ya hay dos dígitos después de la coma
                event.preventDefault();
            }
        }
    } else if (key !== ',') {
        // Previene la entrada de cualquier otro carácter que no sea un número o coma
        event.preventDefault();
    }
}

export function datePickerStart(maxDate: any){
  const today = new Date();
  maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
}
